<template>
  <TepmplateBlock mod-class content-class="mytechnique-wrap">
    <h2 class="mytechnique__title mt-4">Компании</h2>

    <b-input
      size="sm"
      placeholder="Поиск названию компании"
      class="mb-4"
      v-model="searchByCompanyName"
      type="search"
    />

    <div class="scroll-container">
      <vuescroll :ops="ops" @handle-scroll="handleScroll">
        <table class="table table-striped table-sm">
          <thead>
            <tr class="table-primary">
              <th scope="col">№пп</th>
              <th scope="col">
                <span id="popaover-header" variant="primary">ID</span>
                <b-popover
                  target="popaover-header"
                  title="Prop Examples"
                  triggers="hover focus"
                  >HEADER<br />example<br />exampl<br />examplee</b-popover
                >
              </th>

              <th scope="col">Название</th>
              <th scope="col" class="colSmall">ИНН</th>
              <th scope="col">Юр.лицо</th>
              <th scope="col">Юр.Адрес</th>
              <!-- y<th scope="col">email</th> -->
              <th scope="col">Менеджер</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(company, index) in filteredCompanies" :key="company.id">
              <th scope="row">{{ index + 1 }}</th>
              <td>
                <span :id="`popaover-${index + 1}`" variant="primary">{{
                  company.id
                }}</span>
                <b-popover
                  :target="`popaover-${index + 1}`"
                  title="Prop Examples"
                  triggers="hover focus"
                  >{{
                    index + 1
                  }}
                  example<br />example<br />exampl<br />examplee</b-popover
                >
              </td>

              <td>{{ company.brand }}</td>
              <td class="colSmall">{{ company.inn }}</td>
              <td>{{ company.legalEntity }}</td>
              <td>{{ company.legalEntityAddress }}</td>
              <!-- <td>{{ company.email }}</td> -->
              <!-- <td>{{ company.crmManager }}</td> -->
              <td>
                {{ company.crmManager.email }}<br />{{
                  company.crmManager.firstName
                }}<br />{{ company.crmManager.lastName }}
              </td>
              <td>
                <div>
                  <router-link :to="'/company-info/' + company.id">
                    <img src="../../resource/img/linkIcon.svg" alt="" />
                  </router-link>
                </div>
                <div @click="showCRMManagerResetupModal(company)">
                  <img
                    src="../../resource/img/plus_form.svg"
                    width="16"
                    height="16"
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </vuescroll>
    </div>

    <CRMManagerResetup
      v-if="isCRMManagerResetupVisible"
      :users-list="usersList.items"
      @close="hideCRMManagerResetupModal"
      @set-user="setManagerToSelectedCompany"
    ></CRMManagerResetup>
  </TepmplateBlock>
</template>

<script>
import TepmplateBlock from "@component/TepmplateBlock";
// import Checkbox from "@component/Form/FormElement/Checkbox";
import RequestManager from "../../function/request/RequestManager";
import { CompanyApi, UserApi } from "../../function/request/apiV2";
import vuescroll from "vuescroll";
import CRMManagerResetup from "@/components/Modal/CRMManagerResetup";
// import HelpPopover from "@/components/HelpPopover";
//import { mapGetters } from "vuex";
export default {
  name: "Company",
  components: { TepmplateBlock, vuescroll, CRMManagerResetup },
  data() {
    return {
      companyApi: new CompanyApi(),
      userApi: new UserApi(),
      currentUser: undefined,
      searchByCompanyName: "",
      deleteManagerCompanyId: null,
      showModal: false,
      noAva: require("../../resource/img/noUserAva.png"),
      ops: {
        scrollPanel: {
          speed: 300,
          // maxHeight: 500,
          scrollingX: false
        }
      },
      companies: [],
      users: [],
      usersList: [],
      currentCompany: null,
      isCRMManagerResetupVisible: false,
      selectedCompany: {}
    };
  },
  computed: {
    filteredCompanies() {
      //return this.companies.sort( (a, b) => a.id - b.id );

      return this.companies?.filter(el =>
        el.brand?.toLowerCase().includes(this.searchByCompanyName.toLowerCase())
      );
    }
  },
  async mounted() {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.usersList = await this.userApi.api.listUsers({
      companyId: this.currentUser.company,
      limit: 10000
    });
    this.getCompanies();
  },
  methods: {
    async getCompanies() {
      if (this.currentUser.role === "1") {
        const { items } = await this.companyApi.api.listCompanies({
          limit: 1000000,
          sort: "id"
          //crmManagerId: this.currentUserId
        });
        this.companiesCount = items.length;
        this.companies = items;
      }
      if (this.currentUser.role === "2") {
        const items1 = await this.companyApi.api.listCompanies({
          ids: [this.currentUser.company],
          limit: 1000000,
          sort: "id"
        });

        const items2 = await this.companyApi.api.listCompanies({
          limit: 1000000,
          sort: "id",
          crmManagerId: this.currentUser.id
        });

        this.companiesCount = items1.length + items2.length;
        this.companies = [...items1.items, ...items2.items];
      }
    },
    getUserInfo(user) {
      return `${user.last_name ? user.last_name : ""} ${
        user.first_name ? user.first_name : ""
      }`;
    },
    onDeleteClick(id) {
      this.deleteManagerCompanyId = id;
      // this.deletedManager = this.users.find(user => user.id === id);
      this.showModal = true;
    },
    onOkClick() {
      const data = { crm_manager: null };
      RequestManager()
        .updateCompanyInfoPartial(this.deleteManagerCompanyId, data)
        .then(data => {
          this.companies = this.companies.map(company => {
            if (company.id === data.id) {
              return { ...company, crm_manager: null, manager: null };
            }
            return company;
          });
          this.showModal = false;
        });
    },
    handleScroll(vertical) {
      if (
        vertical.process > 0.99 &&
        this.companies.length < this.companiesCount
      ) {
        RequestManager()
          .getCompanies(this.companies.length)
          .then(data => {
            this.companies = [...this.companies, ...data.results];
            this.companiesCount = data.count;
          });
      }
    },
    onAddManagerClick(id) {
      this.currentCompany = id;
      this.$bvModal.show("modal-1");
    },
    onItemClick(id) {
      const data = { crm_manager: id };
      RequestManager()
        .updateCompanyInfoPartial(this.currentCompany, data)
        .then(data => {
          const managerInfo = this.users.find(
            elem => elem.id === data.crm_manager
          );
          this.companies = this.companies.map(company => {
            if (company.id == this.currentCompany) {
              return {
                ...company,
                crm_manager: data.crm_manager,
                manager: managerInfo
              };
            }
            return company;
          });
          this.$bvModal.hide("modal-1");
        });
    },
    showCRMManagerResetupModal(company) {
      this.selectedCompany = company;
      this.isCRMManagerResetupVisible = true;
    },
    hideCRMManagerResetupModal() {
      this.isCRMManagerResetupVisible = false;
    },
    async setManagerToSelectedCompany(userId) {
      this.selectedCompany.crmManagerId = userId;
      await this.companyApi.api.editCompany(this.selectedCompany.id, {
        body: this.selectedCompany
      });
      this.hideCRMManagerResetupModal();
      this.getCompanies();
    }
  }
};
</script>

<style lang="scss" scoped>
.scroll-container {
  width: 100%;
  //height: 100%;
  overflow: hidden;
}
.scroll-container-modal {
  width: 90%;
  max-height: 100%;
  overflow: hidden;
  .__vuescroll {
    width: 100% !important;
  }
}
.chat-btn {
  display: block;
  background-color: transparent;
  border: 2px solid #ffffff;
  border-radius: 6px;
  width: 100%;
  padding: 16px 25px;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  cursor: pointer;
}
.chat-btn:hover {
  background: #f3f9fb;
}
.colored {
  background: #f3f9fb;
}

.manager-btn {
  display: flex;
  justify-content: center;
  width: 30px;
  height: 30px;
  align-items: center;
  border: 2px solid #016b90;
  border-radius: 10px;
  box-sizing: border-box;
  cursor: pointer;
}
.manager-btn-text {
  color: #016b90;
  font-size: 50px;
}
.plus-btn__btn-chat-wrap {
  display: flex;
  padding-top: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box !important;
  a {
    box-sizing: border-box !important;
  }
}
.table {
  border-left: 2px solid #daebf0;
  overflow: auto;
  .link {
    width: 4.1%;
    background: #fafafc;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .col1,
  .col2,
  .col3,
  .col4,
  .col5,
  .col6,
  .col7 {
    border: 2px solid #daebf0;
    border-bottom: 0;
    border-left: 0;
    padding: 12px 17px;
  }
  div:last-of-type {
    div {
      border-bottom: 2px solid #daebf0;
    }
    div:last-of-type {
      border-bottom: 0;
    }
  }
}

.col1 {
  width: 19.4%;
}
.col2 {
  width: 13.3%;
}
.col3 {
  width: 18.1%;
}
.col4 {
  width: 20.4%;
}
.col5 {
  width: 8%;
}
.col6 {
  padding: 0 2px;
  width: 6%;
}
.col7 {
  width: 10.5%;
}
.column-header {
  padding: 0 !important;
  font-size: 15px;
  font-weight: bold;
  color: #4b4b67;
  text-align: left;
  padding-bottom: 18px;

  & > * {
    font-size: 14px;
  }
}
.mytechnique__title {
  margin: 0;
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #4b4b67;
}

.form-mytechnique {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  margin-bottom: 18px;
}

.form-mytechnique__icon {
  &::before {
    content: "";
    position: absolute;
    top: 13px;
    left: 16px;
    width: 24px;
    height: 24px;
    background-image: url("../../resource/img/lupa.svg");
    background-repeat: no-repeat;
    background-position: center center;
    z-index: 1;
  }
}

.form-mytechnique__search {
  position: relative;
  width: 330px;
  height: 50px;
  padding-left: 50px;

  border: 1px solid rgba(206, 206, 206, 0.2);
  background-color: rgba(206, 206, 206, 0.2);
  border-radius: 6px;

  //background-color: rgba(206, 206, 206, 0.2);

  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.04em;
}

.form-mytechnique__sort-btn {
  position: relative;
  display: flex;
  align-items: center;
  background-color: transparent;
  border: 0;
  width: 220px;
  height: 16px;

  padding: 15px 16px 14px;

  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #006b90;

  span {
    display: inline-block;
    margin-left: 16px;
  }

  &::before {
    content: "";
    position: absolute;
    top: 8px;
    left: 8px;
    width: 18px;
    height: 15px;
    background-image: url("../../resource/img/sort.svg");
    background-repeat: no-repeat;
    background-position: center center;
    z-index: 1;
  }

  &_active {
    background: #006b90;
    border-radius: 6px;
    color: #ffffff;

    &::before {
      background-image: url("../../resource/img/sort-hv.svg");
    }
  }
}

.form-mytechnique__checkbox-wrap {
  width: 50%;
  margin-bottom: 14px;
  //&:nth-child(n+3) {
  //  margin-bottom: 27px;
  //}
}

.form-mytechnique__filter-btn {
  position: absolute;
  top: 10px;
  right: 10px;

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  padding: 6px 15px;
  width: 30px;
  height: auto;
  border-radius: 50%;
  border: 0;

  &:hover,
  &:focus {
    background: rgba(8, 116, 162, 0.15);
  }

  img {
    width: 18px;
    height: 18px;
  }
}

.form-mytechnique__popover {
  padding: 30px 28px 28px;
  width: 331px;
  height: 300px;
  background: #ffffff;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}
.form-mytechnique__title {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
  color: #4b4b67;
}

.form-mytechnique__checkbox-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 15px;
}

.company-table {
  width: 100%;
  height: 100%;
  text-align: left;
  border-collapse: collapse;
}

.company-table td,
.company-table th {
  padding: 12px 17px;
}

.company-table__thead th {
  padding: 0;
}

.company-table__tbody td {
  border: 2px solid #daebf0;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  color: #4b4b67;

  &:last-child {
    border: 0;
    background: #fafafc;
  }
}
.company-table tr:nth-child(even) {
  background: #f3f9fb;
}

.company-table thead th {
  font-size: 15px;
  font-weight: bold;
  color: #4b4b67;
  text-align: left;
  padding-bottom: 18px;
}

.company-table__manager-ava {
  border-radius: 50%;
}

.company-table__btn {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: 0;
  font-size: 0;

  &::before {
    content: "";
    position: absolute;
    top: -22px;
    background-image: url("../../resource/img/add-btn.svg");
    background-size: cover;
    width: 68px;
    height: 50px;
    left: 0;
  }
}

.list-enter-active,
.list-leave-active {
  transition: all 0.3s;
}
.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}
.__vuescroll {
  height: calc(100vh - 230px) !important;
}

.colSmall {
  max-width: 120px !important;
}
</style>
