<template>
  <form class="wrapper" ref="form">
    <div class="observer position-relative">
      <div class="modal-title">Title</div>
      <img
        src="../../resource/img/modal/close2.svg"
        alt="close"
        @click="close"
        class="close"
      />
      <div class="observer__wrapper p-2">
        <div
          v-for="(user, idx) in usersList"
          :key="idx"
          class="d-flex align-items-center mb-1 pseudo-link"
          @click="emitUserId(user.id)"
        >
          <div class="fio d-flex align-items-center">
            <div>{{ user.firstName }}</div>
            <div class="ml-1">{{ user.lastName }}</div>
          </div>
          <div class="ml-3 text-muted">{{ user.email }}</div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  name: "CRMManagerResetup",
  props: {
    usersList: {
      type: Array,
      require: true
    }
  },
  data() {
    return {
      isDataSending: false
    };
  },

  methods: {
    close() {
      this.$emit("close");
    },
    emitUserId(userId) {
      this.$emit("set-user", userId);
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: #3c3c3cc4;
  z-index: 1;
}

.observer {
  padding: 66px 0 82px 0;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 640px;
  height: auto;
  background: var(--main-card-color);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  z-index: 1;
  &__select {
    position: relative;
    &__img {
      position: absolute;
      top: calc(50% - 7px);
      right: -60px;
      transform: translateY(-50%);
      cursor: pointer;
    }
  }
  &__wrapper {
    max-width: 850px;
    margin: 0 auto;
    height: 350px;
    overflow-y: auto;
  }
  .close {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 12px;
    height: 12px;
  }
}

.modal-title {
  position: absolute !important;
  top: 10px;
  left: 10px;
  font-size: 1.2em;
}

.pseudo-link {
  cursor: pointer;
}

.fio {
  width: 50%;
}
</style>
